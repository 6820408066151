document.addEventListener('DOMContentLoaded', () => {
  const youTubeCard = document.querySelector('.youtube-card')
  if (!youTubeCard) return

  let youTubeCardInView

  function buildVideo (container) {
    const video = document.createElement('video')
    video.src = 'https://carwow-uk-3.imgix.net/homepage/video/youtube.mp4'
    video.autoplay = true
    video.loop = true
    video.muted = true
    video.playsinline = true
    container.appendChild(video)
  }

  const observer = new window.IntersectionObserver((entries) => {
    entries.forEach(entry => {
      if (entry.target === youTubeCard) {
        youTubeCardInView = entry.isIntersecting
      }

      if (youTubeCardInView) {
        const videoContainer = entry.target.querySelector('.youtube-card__video-container')
        buildVideo(videoContainer)
        observer.unobserve(entry.target)
      }
    })
  })

  observer.observe(youTubeCard)
})
